<template>
  <div v-loading = "pageloading">
    <div v-if="isAuth">
      <slot></slot>
    </div>
    <el-card v-else>
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-c8b01cda-ac9f-464c-ab7c-73bb1d848b9d.png">
					<div class="info">
						<div class="tit">员工活码</div>
						<div class="tip">商家可选择指定员工生成活码，活码可投放至小程序商城内的各页面:商品详情、商城活动推广页、商城首页等。客户在浏览商品或商城页面时，引导客户主动添加员工企业微信。支持给客户打上标签、发送指定欢迎语等</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="handleUse">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
        <div>
          <div class="desc-title">1/功能说明</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-a32fa0d6-a1d4-4218-85bc-088e8a891229.png" />
        </div>
				<div>
          <div class="desc-title">2/后台添加员工活码</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-47eef238-862b-4d29-a3b9-0a2623df6856.png" />
        </div>
				<div>
          <div class="desc-title">3/商家投放添加企业微信入口，在商城小程序内实现涨粉</div>
					<div class = "sec-title" style="margin-top:0px">3-1 入口一：商品详情页让利涨粉</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-46ca872c-e3fc-4fc7-aca5-1bb29d97d669.png" />
        	<div class = "sec-title">3-2 入口二：商城装修投放涨粉入口</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-5bd6e029-cd00-4360-af73-c7e0ef3af6fe.png" />
        	<div class = "sec-title">3-3 入口三：支付成功页引导涨粉入口</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-edda06f0-d957-4346-b793-2285fac47c9c.png" />
        </div>
			</div>
		</el-card>
  </div>
</template>

<script>
import {
		qyweixinsuiteauthinfo,
		qyweixincontactvaliteqyweixincontactplugin
	} from "@/api/sv1.0.0";
export default {
  props:{
  },
  data () {
    return {
      
      isAuth:true,
			pageloading:false,
    }
  },
  created () {
    // console.log(this.titmsg)
    this.getData()
  },
  methods: {
    handleUse() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
			},
			async getData(callback) {
				try {
					const res = await qyweixinsuiteauthinfo();
					this.isAuth = res.Result.IsFinishQyWeixinSuiteStp;
					if(res.Result.IsFinishQyWeixinSuiteStp){
						this.$emit('nextstep')
					}
				} catch (e) {
					console.log(e)
				}
			},
  }
}
</script>

<style lang="less" scoped>
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.sec-title{
		font-size: 14px;
		color: #909399;
		margin-top:30px
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 0px 15px;
		height: 80px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>