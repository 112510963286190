<template>
<div>
		<div v-loading="pageloading">
			<authorization @nextstep="nextstep">
				<el-card>
					<div class="flexRow" style="justify-content: space-between;">
						<div class="flexRow" style="flex-wrap:wrap;flex:1;">
							<div class="flexRow" style="margin:0px 15px">
								<div class="keyFont">关键字：</div>
								<el-input v-model="keywords" placeholder="备注" style="width:180px"></el-input>
							</div>
							<div class="flexRow">
								<el-button type="primary" style="height:36px" @click="query">查询</el-button>
								<el-button type="primary" style="height:36px;margin:0px 15px" @click="jump(null)">添加
								</el-button>
							</div>
							<div class="flexRow">
								<el-button type="text" @click="torisefans" style="line-height:1.3">
									配置员工活码入口，沉淀客户至企业微信 >>>
								</el-button>
							</div>
						</div>
						<qr-codepop :downloadData="experienceCode" :downFont="'功能体验码'"></qr-codepop>
					</div>
					<div style="margin-top:10px">
						<el-table :data="tablelist" v-loading="tableloading" class="callmetable"
							:header-row-style="{'height':'48px'}">
							<el-table-column prop="" label="适用员工">
								<template slot-scope="scope">
									<div class="flexRow">
										<el-tag style="margin:0px 5px 5px 0px" class="flexRow thetag" type="info" plain
											v-for="(v,i) in scope.row.ApplicableEmployeeDtos" :key="v.Id" v-if="i<2">
											<img v-if="v.WxHeadUrl" :src="v.WxHeadUrl" alt="">
											<i v-else class="el-icon-user"></i>
											<span style="margin-left:5px">{{v.EmployeeName}}</span>
										</el-tag>
										<el-tag v-if="scope.row.EmployeeList.length>2" style="margin:0px 5px 5px 0px"
											class="flexRow thetag" type="info" plain>...</el-tag>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="AddTime" label="创建时间"></el-table-column>
							<el-table-column prop="Remark" label="备注"></el-table-column>
							<el-table-column prop="" label="操作">
								<template slot-scope="scope">
									<el-button type="text" @click="jump(scope.row)">编辑</el-button>
									<el-button type="text" @click="lookdata(scope.row)">数据</el-button>
									<el-button type="text" @click="downloadCode(scope.row)">下载</el-button>
									<el-button type="text" style="color:#f56c6c" @click="deleterow(scope.row)">删除
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div style="text-align:right;margin-top:10px" v-if="total">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</el-card>
			</authorization>
			
			<qr-code :downloadData="downloadData" :qrCodeIshow="qrCodeIshow" :isNeedExchangeToQRCode="false" :title="'下载员工活码'" :downFont="'下载员工活码'"
				@cancel="closeQrCode"></qr-code>
			
		</div>
</div>
</template>

<script>
	import authorization from "./components/authorization"
	import QrCodepop from "@/components/bathQrCodes2"
	import buttonPermissions from '@/components/buttonPermissions';
	
	import QrCode from "@/components/bathQrCodes"


	import {
		qyWeixinContactMeListRequest,
		qyWeixinContactMeDelRequest,
		qyweixincontactvaliteqyweixincontactplugin
	} from "@/api/sv1.0.0";
	
	import {qyWeixinContactMeQrCode} from '@/api/TurnTomySelf'
	
	import {
		mapGetters
	} from "vuex"
	
	export default {
		components: {
			authorization,
			QrCodepop,
			buttonPermissions,
			QrCode,
		},
		data() {
			return {

				experienceCode: {
					url: 'https://cdn.dkycn.cn/melyshop/image/yuangonghuoma.png',
					icon: '',
					name: '',
					link: 'https://jusnn6k8al.feishu.cn/docs/doccnA3KwQBFxX8qKGC7A1TltBc',
				},
				pageloading: false,
				keywords: '',
				tablelist: [],
				tableloading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,
				
				qrCodeIshow: false,
				downloadData: {},
				
			}
		},
		computed: {
			...mapGetters(['pcImg'])
		},
		created() {

		},
		methods: {
			
			//下载群活码
			async downloadCode(record) {
				
				try{
					const res = await qyWeixinContactMeQrCode({
						QyWeixinContactMeId: record.Id
					})
					if (res.IsSuccess){
						this.qrCodeIshow = true
						this.downloadData = {
							url: res.Result.QrCode,
							icon: this.pcImg,
							name: record.Remark,
							link: 'https://jusnn6k8al.feishu.cn/docs/doccnQ07zpjVd2ykMulFsNCblRg'
						}
					}
				}catch(e){
					//TODO handle the exception
				}
				
			},
			closeQrCode(){
				this.qrCodeIshow = false;
			},
			torisefans() {
				this.$router.push({
					path: '/qyCustomer/risefans'
				})
			},
			//删除
			deleterow(e) {
				this.$confirm(`删除后不可恢复，是否确认删除 [${e.Remark}] ？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.todel(e)
				}).catch(() => {})
			},
			async todel(e) {
				this.tableloading = true
				try {
					let Id = await e.Id
					let data = {
						Id: Id
					}
					let result = await qyWeixinContactMeDelRequest(data)
					if (result.IsSuccess) {
						this.$message.success('删除成功')
						this.gettablelist()
					}
				} finally {
					this.tableloading = false
				}
			},
			nextstep() {
				// this.isuse()
				this.gettablelist()
			},
			async isuse() {
				this.pageloading = true
				try {
					let data = {
						noError: true
					}
					let result = await qyweixincontactvaliteqyweixincontactplugin(data)
					// result = {
					//   ErrorCode:61007,
					//   IsSuccess:false,
					// }
					if (result.IsSuccess) {
						this.gettablelist()
					} else {
						if (result.ErrorCode == 61007) {
							const h = this.$createElement;
							// console.log(123)
							this.$msgbox({
								title: '提示',
								message: h('p', {
									'style': 'line-height:1.5'
								}, [
									h('span', null,
										'您的商城还未授权小程序；或已授权的商城小程序，缺少[小程序插件管理]权限，请前往[销售渠道]—[微信小程序]—[商城小程序]，点击重新授权，将 '
										),
									h('span', {
										'style': 'color:#f56c6c'
									}, '[小程序插件管理] '),
									h('span', null, '权限授权给企店 '),
								]),
								type: 'warning',
								confirmButtonText: '前往重新授权',
								showCancelButton: false,
								closeOnClickModal: false,
								closeOnPressEscape: false,
								showClose: false,
								// cancelButtonText: '关闭',
							}).then(() => {
								this.$router.push({
									path: '/setting/wxAppManage'
								})
								// done()
							}).catch(() => {})
						} else {
							this.$message.error(result.Message)
						}
					}
				} finally {
					this.pageloading = false
				}
			},
			//添加 编辑
			jump(e) {
				let Id = 0
				// console.log(e)
				if (e) {
					Id = e.Id
				}
				this.$router.push({
					path: '/qyCustomer/callmeedit',
					query: {
						Id: Id
					}
				})
			},
			lookdata(e) {
				this.$router.push({
					path: '/qyCustomer/callmedata',
					query: {
						Id: e.Id
					}
				})
			},
			//查询
			query() {
				this.currentPage = 1
				this.gettablelist()
			},
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.gettablelist()
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.gettablelist()
			},
			async gettablelist() {
				this.tableloading = true
				try {
					let data = {
						KeyWords: this.keywords,
						Skip: (this.currentPage - 1) * this.sizepage,
						Take: this.sizepage
					}
					let result = await qyWeixinContactMeListRequest(data)
					if (result.IsSuccess) {
						this.tablelist = result.Result.Results
						this.total = result.Result.Total
						// console.log(result.Result)
					}
				} finally {
					this.tableloading = false
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.flexRow {
		display: flex;
		flex-direction: row;
		/* justify-content: space-between; */
		align-items: center;
	}

	.keyFont {
		font-size: 14px;
		font-weight: bold;
		word-break: normal;
		color: #606266;
	}

	.thetag img {
		width: 20px;
		height: 20px;
		border-radius: 3px;
	}
</style>
<style>
	.callmetable .el-table__body td,
	.el-table__body th {
		padding: 0px !important;
		height: 60px;
	}
</style>
